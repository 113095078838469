import React from "react";
import classes from "./Intro.module.scss";
import classNames from "classnames";
import { BlurBg } from "@elements";
export default function Intro({
  greetName,
  wGreet,
  srcBg,
  smallSrcBg,
  moreHandler,
}) {
  return (
    <div
      className={classNames(
        classes.intro,
        "fixed left-0 right-0 top-0 bottom-0 h-full"
      )}
    >
      <BlurBg
        smallSrc={smallSrcBg}
        src={srcBg}
        classCustom={classNames(
          classes.bg,
          "bg-cover bg-center bg-no-repeat absolute top-0 bottom-0 left-0 right-0 h-full z-0"
        )}
      />
      <div
        className="absolute top-0 bottom-0 left-0 right-0 h-full z-10 "
        style={{ backgroundColor: "rgba(0,0,0,.2)" }}
      />
      <div
        className={classNames(
          classes.content,
          "absolute px-8 w-full flex flex-col items-center z-20",
          { [classes.wGreet]: wGreet }
        )}
      >
        <h4 className="text-white text-lg">The Wedding Of</h4>
        <h1
          className={classNames(
            classes.fontCustom,
            "mt-2 text-white text-4xl",
            { "mt-4": !wGreet }
          )}
        >
          Bernandine & Rifqi
        </h1>
        <p
          className={classNames("mt-2 text-white text-lg", { "mt-4": !wGreet })}
        >
          25 . 07 . 21
        </p>
        {wGreet && (
          <p className="mt-8 text-white text-center text-lg">
            Dear {greetName?.length < 1 ? "Bapak/Ibu/Saudara/i" : greetName}
          </p>
        )}
        <a
          className={classNames(
            "cursor-pointer mt-4 py-2 px-6 border rounded block text-white",
            { "mt-8": !wGreet }
          )}
          onClick={moreHandler}
        >
          You're invited!
        </a>
      </div>
    </div>
  );
}
