import "@assets/styles/app.css";
import Routes from "@routes";
import { Router } from "react-router-dom";
import { ScrollToTop } from "@components";
import { history } from "@utils";
function App() {
  return (
    <Router basename="/" history={history}>
      <ScrollToTop>
        <Routes />
      </ScrollToTop>
    </Router>
  );
}

export default App;
