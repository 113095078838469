import React, { useRef, useCallback, useEffect } from "react";
import classNames from "classnames";
import classes from "./Closing.module.scss";
import { useInView } from "react-intersection-observer";
import { gsap, Power2 } from "gsap";

export default function Closing() {
  const contentRef = useRef();
  const item1Ref = useRef();
  const item2Ref = useRef();
  const item3Ref = useRef();
  const item4Ref = useRef();
  const item5Ref = useRef();
  const [inViewContentRef, inViewContent] = useInView({
    trackVisibility: true,
    delay: 100,
    triggerOnce: true,
  });

  const setContentRefs = useCallback(
    (node) => {
      contentRef.current = node;
      inViewContentRef(node);
    },
    [inViewContentRef]
  );
  useEffect(() => {
    if (inViewContent) animate();
  }, [inViewContent]);

  const animate = () => {
    let tl = gsap.timeline();
    tl.fromTo(
      item1Ref.current,
      {
        opacity: 0,
        y: "4rem",
        ease: Power2.easeInOut,
      },
      {
        opacity: 1,
        y: 0,
        ease: Power2.easeInOut,
        duration: 0.5,
      }
    );
    tl.fromTo(
      item2Ref.current,
      {
        opacity: 0,
        y: "4rem",
        ease: Power2.easeInOut,
      },
      {
        opacity: 1,
        y: 0,
        ease: Power2.easeInOut,
        duration: 0.5,
      }
    );
    tl.fromTo(
      item3Ref.current,
      {
        opacity: 0,
        y: "4rem",
        ease: Power2.easeInOut,
      },
      {
        opacity: 1,
        y: 0,
        ease: Power2.easeInOut,
        duration: 0.5,
      }
    );
    tl.fromTo(
      item4Ref.current,
      {
        opacity: 0,
        y: "4rem",
        ease: Power2.easeInOut,
      },
      {
        opacity: 1,
        y: 0,
        ease: Power2.easeInOut,
        duration: 0.5,
      }
    );
    tl.fromTo(
      item5Ref.current,
      {
        opacity: 0,
        y: "4rem",
        ease: Power2.easeInOut,
      },
      {
        opacity: 1,
        y: 0,
        ease: Power2.easeInOut,
        duration: 0.5,
      }
    );
  };
  return (
    <div
      ref={setContentRefs}
      className={classNames(classes.content, classes.vibes)}
    >
      <div className="mt-12 px-8">
        <p ref={item1Ref}>
          Demikian undangan ini kami sampaikan, atas pengertian dan doa restu
          Bapak/Ibu/Saudara/i kami ucapkan terima kasih.
        </p>
        <p ref={item2Ref} className="mt-4">
          Wassalamualaikum warahmatullahi wabarakatuh
        </p>
        <p ref={item3Ref} className="mt-4">
          Kami yang berbahagia,
        </p>
        <div className="mt-8">
          <div ref={item4Ref}>
            <p>Kel. Bapak Sudjoni</p>
            <p>Ibu Elda Surjani</p>
          </div>
          <div
            className="my-4 mx-auto"
            style={{
              width: 150,
              height: 1,
              backgroundColor: "#949C8F",
            }}
          />
          <div ref={item5Ref}>
            <p>Kel. Bapak Gatot Sudaryono</p>
            <p>Ibu Dahlina</p>
          </div>
        </div>
      </div>
    </div>
  );
}
