import React, { useRef, useCallback, useEffect } from "react";
import classNames from "classnames";
import classes from "./SemiClose.module.scss";
import { qrStreamFlat } from "@images";
import { useInView } from "react-intersection-observer";
import { gsap, Power2 } from "gsap";

export default function SemiClose({ isComing }) {
  const semiContentRef = useRef();
  const item1Ref = useRef();
  const item2Ref = useRef();
  const item3Ref = useRef();
  const item4Ref = useRef();
  const item5Ref = useRef();
  const [inViewSemiContentRef, inViewSemiContent] = useInView({
    trackVisibility: true,
    delay: 100,
    triggerOnce: true,
  });

  const setSemiContentRefs = useCallback(
    (node) => {
      semiContentRef.current = node;
      inViewSemiContentRef(node);
    },
    [inViewSemiContentRef]
  );
  useEffect(() => {
    if (inViewSemiContent) animate();
  }, [inViewSemiContent]);

  const animate = () => {
    let tl = gsap.timeline();
    tl.fromTo(
      item1Ref.current,
      {
        opacity: 0,
        y: "4rem",
        ease: Power2.easeInOut,
      },
      {
        opacity: 1,
        y: 0,
        ease: Power2.easeInOut,
        duration: 0.5,
      }
    );
    tl.fromTo(
      item2Ref.current,
      {
        opacity: 0,
        y: "4rem",
        ease: Power2.easeInOut,
      },
      {
        opacity: 1,
        y: 0,
        ease: Power2.easeInOut,
        duration: 0.5,
      }
    );
    tl.fromTo(
      item3Ref.current,
      {
        opacity: 0,
        y: "4rem",
        ease: Power2.easeInOut,
      },
      {
        opacity: 1,
        y: 0,
        ease: Power2.easeInOut,
        duration: 0.5,
      }
    );
    tl.fromTo(
      item4Ref.current,
      {
        opacity: 0,
        y: "4rem",
        ease: Power2.easeInOut,
      },
      {
        opacity: 1,
        y: 0,
        ease: Power2.easeInOut,
        duration: 0.5,
      }
    );
    tl.fromTo(
      item5Ref.current,
      {
        opacity: 0,
        y: "4rem",
        ease: Power2.easeInOut,
      },
      {
        opacity: 1,
        y: 0,
        ease: Power2.easeInOut,
        duration: 0.5,
      }
    );
  };

  return (
    <div
      ref={setSemiContentRefs}
      className={classNames(classes.content, classes.vibes, "mt-12")}
    >
      {isComing ? (
        <div className="px-8">
          <p ref={item1Ref}>
            Demikian undangan ini kami sampaikan, atas pengertian dan doa restu
            Bapak/Ibu/Saudara/i kami ucapkan terima kasih.
          </p>
          <p ref={item2Ref} className="mt-4">
            Wassalamualaikum warahmatullahi wabarakatuh
          </p>
          <p ref={item3Ref} className="mt-4">
            Kami yang berbahagia,
          </p>
          <div ref={item4Ref} className="mt-8">
            <div>
              <p>Kel. Bapak Sudjoni</p>
              <p>Ibu Elda Surjani</p>
            </div>
            <div
              className="my-4 mx-auto"
              style={{
                width: 150,
                height: 1,
                backgroundColor: "#949C8F",
              }}
            />
            <div ref={item5Ref}>
              <p>Kel. Bapak Gatot Sudaryono</p>
              <p>Ibu Dahlina</p>
            </div>
          </div>
        </div>
      ) : (
        <div className="px-8">
          <p ref={item1Ref}>
            Berkaitan dengan pandemi Covid-19 yang masih berlangsung, mohon
            maklum bahwa acara ini akan dilangsungkan secara terbatas untuk
            keluarga kedua mempelai.
          </p>
          <p ref={item2Ref} className="mt-8">
            Namun, tanpa mengurangi rasa hormat, izinkan kami mengundang
            Bapak/Ibu/Saudara/i untuk hadir dan memberikan doa restu secara
            online melalui:
          </p>
          <h2 ref={item3Ref} className="mt-8 font-bold">
            Live Streaming
          </h2>
          <div ref={item4Ref} className="mt-4 mx-auto" style={{ width: 120 }}>
            {/* <QrStream /> */}
            <img src={qrStreamFlat} style={{ maxWidth: "100%" }} alt="" />
          </div>
          <a
            ref={item5Ref}
            className="mt-4 block underline"
            href="https://pozefoto.com/RIFBERsatu"
            style={{ color: "#AC8264" }}
            target="_blank"
            rel="noreferrer"
          >
            https://pozefoto.com/RIFBERsatu
          </a>
        </div>
      )}
    </div>
  );
}
