import React from "react";
import classNames from "classnames";
import classes from "./MainBanner.module.scss";
import { logoBR2 } from "@images";
export default function MainBanner() {
  return (
    // <div className={classNames(classes.mainBanner, "pt-12 pb-8")}>
    <div className={classNames(classes.mainBanner)}>
      <div className="px-8 flex flex-col items-center">
        {/* <LogoBR2 style={{ width: "5rem", color: "inherit" }} /> */}
        {/* <h1 className={classes.fonts}>br</h1> */}
        <div style={{ width: 180 }}>
          <img src={logoBR2} alt="" />
        </div>
        <h2 className="text-md">The Day when #RIFBERsatu</h2>
      </div>
    </div>
  );
}
