import React, { useState, useEffect, forwardRef } from "react";
import classes from "./BlurBg.module.scss";
import classNames from "classnames";
// import Loader from "../Loader/Loader";

const BlurBg = forwardRef(
  ({ children, loaded, src, smallSrc, classCustom, styleCustom }, ref) => {
    const [imgSrc, setImgSrc] = useState();
    // const [loadedBg, setLoadedBg] = useState(false);

    const loadImage = () => {
      let img = new Image();
      img.onload = () => {
        // setLoadedBg(true);
        setImgSrc(src);
      };
      img.src = src;
    };

    useEffect(() => {
      setImgSrc(smallSrc);
      // setLoadedBg(loaded);
      loadImage();
    }, []);

    return (
      <div
        className={classNames(classes.bg, classCustom)}
        style={{ backgroundImage: `url(${imgSrc})`, ...styleCustom }}
        ref={ref}
      >
        {children}
        {/* {!loadedBg && <Loader className={classes.loader} />} */}
      </div>
    );
  }
);

export default BlurBg;
