import React, { useEffect, useRef, useCallback } from "react";
import classNames from "classnames";
import classes from "./When.module.scss";
import { qrStreamFlat } from "@images";
import { useInView } from "react-intersection-observer";
import { gsap, Power2 } from "gsap";

export default function When({ isComing }) {
  const saveDateRef = useRef();
  const titleRef = useRef();
  const dateRef = useRef();
  const locationRef = useRef();
  const streamRef = useRef();
  const [inViewSaveDateRef, inViewSaveDate] = useInView({
    trackVisibility: true,
    delay: 100,
    triggerOnce: true,
  });

  const setSaveDateRefs = useCallback(
    (node) => {
      saveDateRef.current = node;
      inViewSaveDateRef(node);
    },
    [inViewSaveDateRef]
  );
  useEffect(() => {
    if (inViewSaveDate) animate();
  }, [inViewSaveDate]);

  const animate = () => {
    let tl = gsap.timeline();
    tl.fromTo(
      titleRef.current,
      {
        opacity: 0,
        y: "4rem",
        ease: Power2.easeInOut,
      },
      {
        opacity: 1,
        y: 0,
        ease: Power2.easeInOut,
        duration: 0.5,
      }
    );
    tl.fromTo(
      dateRef.current,
      {
        opacity: 0,
        y: "4rem",
        ease: Power2.easeInOut,
      },
      {
        opacity: 1,
        y: 0,
        ease: Power2.easeInOut,
        duration: 0.5,
      }
    );
    if (isComing) {
      tl.fromTo(
        locationRef.current,
        {
          opacity: 0,
          y: "4rem",
          ease: Power2.easeInOut,
        },
        {
          opacity: 1,
          y: 0,
          ease: Power2.easeInOut,
          duration: 0.5,
        }
      );
      tl.fromTo(
        streamRef.current,
        {
          opacity: 0,
          y: "4rem",
          ease: Power2.easeInOut,
        },
        {
          opacity: 1,
          y: 0,
          ease: Power2.easeInOut,
          duration: 0.5,
        }
      );
    }
  };

  return (
    <div
      className={classNames(classes.content, classes.vibes, "mt-12 xl:mt-16")}
    >
      <h1
        ref={setSaveDateRefs}
        className="text-4xl md:text-5xl"
        style={{ color: "#AC8264" }}
      >
        Save the Date!
      </h1>
      <h2 ref={titleRef} className="mt-8 font-bold text-lg md:text-xl">
        Akad
      </h2>
      <div ref={dateRef}>
        <h4 className="mt-8 font-bold text-sm md:text-md leading-3 md:leading-4">
          MINGGU
        </h4>
        <h3 className="font-bold text-4xl md:text-5xl">25</h3>
        <h4 className="font-bold text-lg md:text-xl leading-5 md:leading-6 tracking-widest">
          JULI
        </h4>
        <h4 className="font-bold text-lg md:text-xl leading-5 md:leading-6 tracking-widest">
          2021
        </h4>
        <p className="mt-4 font-bold">08:00 WIB</p>
      </div>

      {isComing && (
        <div className="mt-12">
          <div ref={locationRef}>
            <h4 className="font-bold">Harris Vertu Hotel Harmoni</h4>
            <p>Jl. Hayam Wuruk No. 6, Gambir, Jakarta Pusat</p>
            <a
              className="block mt-4 underline"
              href="https://www.google.com/maps/place/HARRIS+Vertu+Hotel+Harmoni/@-6.1642571,106.82062,15z/data=!4m8!3m7!1s0x0:0xa2c3e2a867b3fc51!5m2!4m1!1i2!8m2!3d-6.1642648!4d106.8205562"
              target="_blank"
              rel="noreferrer"
              style={{ color: "#AC8264" }}
            >
              Peta Lokasi
            </a>
          </div>
          <div className="mt-12" ref={streamRef}>
            <h2 className="font-bold">Live Streaming</h2>
            <div className="mt-4 mx-auto" style={{ width: 85 }}>
              <img src={qrStreamFlat} style={{ maxWidth: "100%" }} alt="" />
            </div>
            <a
              className="mt-4 block underline"
              href="https://pozefoto.com/RIFBERsatu"
              style={{ color: "#AC8264" }}
              target="_blank"
              rel="noreferrer"
            >
              https://pozefoto.com/RIFBERsatu
            </a>
          </div>
        </div>
      )}
    </div>
  );
}
