import React, { useRef, useCallback, useEffect } from "react";
import classNames from "classnames";
import classes from "./Surah.module.scss";
import { useInView } from "react-intersection-observer";
import { gsap, Power2 } from "gsap";

export default function Surah() {
  const contentRef = useRef();
  const item1Ref = useRef();
  const item2Ref = useRef();
  const [inViewContentRef, inViewContent] = useInView({
    trackVisibility: true,
    delay: 100,
    triggerOnce: true,
  });

  const setContentRefs = useCallback(
    (node) => {
      contentRef.current = node;
      inViewContentRef(node);
    },
    [inViewContentRef]
  );
  useEffect(() => {
    if (inViewContent) animate();
  }, [inViewContent]);

  const animate = () => {
    let tl = gsap.timeline();
    tl.fromTo(
      item1Ref.current,
      {
        opacity: 0,
        y: "4rem",
        ease: Power2.easeInOut,
      },
      {
        opacity: 1,
        y: 0,
        ease: Power2.easeInOut,
        duration: 0.5,
      }
    );
    tl.fromTo(
      item2Ref.current,
      {
        opacity: 0,
        y: "4rem",
        ease: Power2.easeInOut,
      },
      {
        opacity: 1,
        y: 0,
        ease: Power2.easeInOut,
        duration: 0.5,
      }
    );
  };
  return (
    <div
      ref={setContentRefs}
      className={classNames(classes.content, classes.vibes)}
    >
      <div className="mt-12 px-8 md:w-10/12 md:mx-auto">
        <p ref={item1Ref} className="italic">
          “Dan di antara tanda-tanda (kebesaran)-Nya ialah Dia menciptakan
          pasangan-pasangan untukmu dari jenismu sendiri, agar kamu cenderung
          dan merasa tenteram kepadanya dan Dia menjadikan di antara kamu rasa
          kasih dan sayang. Sungguh, pada demikian itu benar-benar terdapat
          tanda-tanda (kebesaran Allah) bagi kaum yang berfikir.“
        </p>
        <p
          ref={item2Ref}
          className="mt-4"
          // style={{ color: "#AC8264" }}
          style={{ color: "#AC8264" }}
        >
          (QS. Ar-Rum ayat 21)
        </p>
      </div>
    </div>
  );
}
