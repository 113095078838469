import React, { lazy, Suspense } from "react";
import { Switch, Route, useLocation } from "react-router-dom";
const Home = lazy(() => import("@pages/Home"));

export default function Routes() {
  const location = useLocation();
  return (
    <Suspense fallback="">
      <Switch location={location}>
        <Route exact path="/" component={Home} />
        <Route path="/:state" component={Home} />
      </Switch>
    </Suspense>
  );
}
