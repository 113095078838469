import React, { useState, useRef, useEffect } from "react";
import classNames from "classnames";
import classes from "./CountDown.module.scss";
// import dayjs from "dayjs";
import moment from "moment-timezone";

export default function CountDown({ classCustom }) {
  const [days, setDays] = useState("00");
  const [hours, setHours] = useState("00");
  const [minutes, setMinutes] = useState("00");
  const [seconds, setSeconds] = useState("00");

  let interval = useRef();

  const startTimer = () => {
    const dateStart = moment
      .tz("25 July 2021 08:00:00", "DD MMMM YYYY HH:mm:ss", "Asia/Bangkok")
      .valueOf();
    interval = setInterval(() => {
      const now = moment.tz("Asia/Bangkok").valueOf();
      const diff = dateStart - now;
      const diffDays = Math.floor(diff / (1000 * 60 * 60 * 24));
      const diffHours = Math.floor(
        (diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const diffMinutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
      const diffSeconds = Math.floor((diff % (1000 * 60)) / 1000);
      if (diff < 0) {
      } else {
        setDays(String(diffDays));
        setHours(String(diffHours));
        setMinutes(String(diffMinutes));
        setSeconds(String(diffSeconds));
      }
    }, 1000);
  };
  useEffect(() => {
    startTimer();
    return () => {
      clearInterval(interval.current);
    };
  });

  return (
    <div className={classNames(classCustom, "flex justify-center")}>
      <div
        className={classNames(classes.boxTime, "flex flex-col items-center")}
      >
        <div className="flex">
          <p className="text-xl md:text-2xl border rounded flex justify-center items-center">
            {days?.length > 1 ? days[0] : "0"}
          </p>
          <p className="ml-1 text-xl md:text-2xl border rounded flex justify-center items-center">
            {days?.length > 1 ? days[1] : days[0]}
          </p>
        </div>
        <p className="mt-1 text-xs md:text-sm">days</p>
      </div>
      <div className="mx-2 text-xl md:text-2xl">:</div>
      <div
        className={classNames(classes.boxTime, "flex flex-col items-center")}
      >
        <div className="flex">
          <p className="text-xl md:text-2xl border rounded flex justify-center items-center">
            {hours?.length > 1 ? hours?.[0] : "0"}
          </p>
          <p className="ml-1 text-xl md:text-2xl border rounded flex justify-center items-center">
            {hours?.length > 1 ? hours?.[1] : hours?.[0]}
          </p>
        </div>
        <p className="mt-1 text-xs md:text-sm">hours</p>
      </div>
      <div className="mx-2 text-xl md:text-2xl">:</div>
      <div
        className={classNames(classes.boxTime, "flex flex-col items-center")}
      >
        <div className="flex">
          <p className="text-xl md:text-2xl border rounded flex justify-center items-center">
            {minutes?.length > 1 ? minutes?.[0] : "0"}
          </p>
          <p className="ml-1 text-xl md:text-2xl border rounded flex justify-center items-center">
            {minutes?.length > 1 ? minutes?.[1] : minutes?.[0]}
          </p>
        </div>
        <p className="mt-1 text-xs md:text-sm">minutes</p>
      </div>
      {/* <div className="mx-2 text-xl md:text-2xl">:</div>
      <div className={classNames(classes.boxTime, 'flex flex-col items-center')}>
        <div className="flex">
          <p className="text-xl md:text-2xl border rounded flex justify-center items-center">
            {seconds?.length > 1 ? seconds?.[0] : "0"}
          </p>
          <p className="ml-1 text-xl md:text-2xl border rounded flex justify-center items-center">
            {seconds?.length > 1 ? seconds?.[1] : seconds?.[0]}
          </p>
        </div>
        <p className='mt-1 text-xs md:text-sm'>seconds</p>
      </div> */}
    </div>
  );
}
