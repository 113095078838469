import React, { useRef, useCallback, useEffect } from "react";
import classNames from "classnames";
import classes from "./Who.module.scss";
import { useInView } from "react-intersection-observer";
import { gsap, Power2 } from "gsap";

export default function Who() {
  const salamRef = useRef();
  const person1Ref = useRef();
  const person2Ref = useRef();
  const andRef = useRef();
  const [inViewSalamRef, inViewSalam] = useInView({
    trackVisibility: true,
    delay: 100,
    triggerOnce: true,
  });

  const setSalamRefs = useCallback(
    (node) => {
      salamRef.current = node;
      inViewSalamRef(node);
    },
    [inViewSalamRef]
  );

  useEffect(() => {
    if (inViewSalam) animate();
  }, [inViewSalam]);

  const animate = () => {
    let tl = gsap.timeline();
    tl.fromTo(
      salamRef.current,
      {
        opacity: 0,
        y: "4rem",
        ease: Power2.easeInOut,
      },
      {
        opacity: 1,
        y: 0,
        ease: Power2.easeInOut,
        duration: 0.5,
      }
    );
    tl.fromTo(
      person1Ref.current,
      {
        opacity: 0,
        y: "4rem",
        ease: Power2.easeInOut,
      },
      {
        opacity: 1,
        y: 0,
        ease: Power2.easeInOut,
        duration: 0.5,
      }
    );
    tl.fromTo(
      andRef.current,
      {
        opacity: 0,
        y: "4rem",
        ease: Power2.easeInOut,
      },
      {
        opacity: 1,
        y: 0,
        ease: Power2.easeInOut,
        duration: 0.5,
      }
    );
    tl.fromTo(
      person2Ref.current,
      {
        opacity: 0,
        y: "4rem",
        ease: Power2.easeInOut,
      },
      {
        opacity: 1,
        y: 0,
        ease: Power2.easeInOut,
        duration: 0.5,
      }
    );
  };
  return (
    <>
      <div
        ref={setSalamRefs}
        className={classNames("px-8 mt-12 flex flex-col items-center")}
      >
        <p>Bismillahirrahmanirrahim</p>
        <p className="text-center">
          Assalamualaikum warahmatullahi wabarakatuh,
        </p>
        <p className="mt-6 text-center">
          Dengan rahmat Allah SWT, kami bermaksud menyelenggarakan akad nikah
          putra dan putri kami,
        </p>
      </div>
      <div className={classNames(classes.content, classes.vibes, "px-8 mt-12")}>
        <div ref={person1Ref}>
          <h1 className="text-3xl md:text-4xl" style={{ color: "#AC8264" }}>
            Bernandine Natasha Liemchiu
          </h1>
          <p className="mt-2">
            Putri kedua dari Bpk. Sudjoni dan Ibu Elda Surjani
          </p>
        </div>
        <h1 ref={andRef} className="my-8 text-3xl md:text-4xl" style={{ color: "#AC8264" }}>
          &
        </h1>
        <div ref={person2Ref}>
          <h1 className="text-3xl md:text-4xl" style={{ color: "#AC8264" }}>
            Rifqi Anshari Gana Atmaja
          </h1>
          <p className="mt-2">
            Putra pertama dari Bpk. Gatot Sudaryono dan Ibu Dahlina
          </p>
        </div>
      </div>
    </>
  );
}
